var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "tooltip",
          rawName: "v-tooltip",
          value: _vm.toolTip(_vm.alt),
          expression: "toolTip(alt)",
        },
      ],
      staticClass: "xkcd-wrapper",
    },
    [
      _c("h3", { staticClass: "xkcd-title" }, [_vm._v(_vm._s(_vm.title))]),
      _c("a", { attrs: { href: `https://xkcd.com/${_vm.comicNum}/` } }, [
        _c("img", {
          staticClass: "xkcd-comic",
          attrs: { src: _vm.image, alt: _vm.alt },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }